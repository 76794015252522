<template>
  <!-- <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :gradient="`to bottom, ${barColor}`"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="300"
    v-bind="$attrs"
  > -->
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    app
    dark
    temporary
    mobile-breakpoint="960"
    width="300"
  >
  <!-- <v-navigation-drawer
    v-model="drawerPublic"
    app
    dark
    temporary
  > -->
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />
    <v-treeview :items="items"
      :open="initiallyOpen"
      activatable
      open-on-click
      item-key="current_skey">
      <template v-slot:prepend="{ item, open }">
        <v-icon color="blue lighten-2" v-if="item.node_type==='W'||item.node_type==='w'"><!-- GOLF remove Trim. 2022-11-8 -->
          {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
        </v-icon>
        <v-icon color="blue lighten-2" v-else>mdi-domain
        </v-icon>
      </template>
      <template v-slot:label="{ item }">
        <v-list-item class="pa-0" :to="item.link">{{ item.name }}</v-list-item>
      </template>
    </v-treeview>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'
  import axios from 'axios'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      parentComponent: null,
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      navigation: {
        shown: false,
        width: 300,
        borderSize: 3
      },
      initiallyOpen: ['public'],
      fixed: false,
      menu: [],
      items: [
        {
          action: 'mdi-ticket',
          items: [{ title: 'List Item' }],
          title: 'Attractions',
        },
        {
          action: 'mdi-silverware-fork-knife',
          active: true,
          items: [
            { title: 'Breakfast & brunch' },
            { title: 'New American' },
            { title: 'Sushi' },
          ],
          title: 'Dining',
        },
        {
          action: 'mdi-school',
          items: [{ title: 'List Item' }],
          title: 'Education',
        },
        {
          action: 'mdi-run',
          items: [{ title: 'List Item' }],
          title: 'Family',
        },
        {
          action: 'mdi-bottle-tonic-plus',
          items: [{ title: 'List Item' }],
          title: 'Health',
        },
        {
          action: 'mdi-content-cut',
          items: [{ title: 'List Item' }],
          title: 'Office',
        },
        {
          action: 'mdi-tag',
          items: [{ title: 'List Item' }],
          title: 'Promotions',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      drawerWidth: {
        get () {
          return this.$store.state.drawerWidth
        },
        set (val) {
          this.$store.commit('SET_DRAWER_WIDTH', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },

    created () {
      this.getMenu(this.$i18n.locale)
    },

    mounted () {
      this.setBorderWidth()
      this.setEvents()
    },
  
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      recurMenu: function (level) {
        if (this.menu.filter(x => x.node_level === level).length === 0) {
        } else {
          // console.log(this.menu.filter(x => x.node_level === level))
          var menuLevel = this.menu.filter(x => x.node_level === level)
          for (var i = 0; i < menuLevel.length; i++) {
            if (menuLevel[i]['node_type'].trim() === 'W') {
              var subitem = []
              for (var j = 0; j < this.menu.length; j++) {
                if (this.menu[j]['from_current_skey'] === menuLevel[i]['current_skey']) {
                  if (this.menu[j]['node_type'].trim() === 'W') {
                    this.recurMenu(level + 2)
                  }
                  subitem.push({title: this.menu[j]['node_name'], link: '/' + this.menu[j]['win_name']})
                }
              }
              this.items.push({action: 'folder_open', title: menuLevel[i]['node_name'], link: '/', items: subitem})
            } else {
              this.items.push({title: menuLevel[i]['node_name'], link: '/' + menuLevel[i]['win_name']})
            }
          }
        }
      },
      recurseMenu: function (parent, level) {
        this.htmlStrTxt = '<ul>'
        for (var x in this.menu) {
          if (this.menu[x]['from_current_skey'] === parent) {
            this.htmlStrTxt += '<li><span class="level-' + level + '">' + this.menu[x]['node_name'] + '</span>'
            var currentSkey = this.menu[x]['current_skey']
            if (this.menu.filter(x => x.from_current_skey === currentSkey).length > 0) {
              this.htmlStrTxt += this.recurseMenu(currentSkey, level + 1)
            }
            this.htmlStrTxt += '</li>'
          }
        }
        return this.htmlStrTxt + '</ul>'
      },
      getNestedChildren: function (arr, parent) {
        var out = []
        for (var i in arr) {
          if (arr[i].from_current_skey === parent) {
            var children = this.getNestedChildren(arr, arr[i].current_skey)
            if (children.length) {
              arr[i].children = children
              arr[i].action = 'folder_open'
            }
            arr[i].name = arr[i]['node_name']
            arr[i].title = arr[i]['node_name']
            arr[i].link = arr[i]['win_name']
            out.push(arr[i])
            /* ถ้ามีการเพิ่ม routes.js ให้มาเพิ่มตัวเลขตรงนี้ด้วย */
            var component = this.$router.options.routes[3].children.find(function (x) {
              return (x.name === arr[i]['win_name'])
            })
            if (component !== undefined && component !== null) {
              component.title = arr[i]['node_name']
            }
          }
        }
        return out
      },
      getMenu: function (languageID) {
        this.axios.post(process.env.VUE_APP_API + '/getMenu' + process.env.VUE_APP_DATABASE_TYPE, {
          user_id: localStorage.getItem('username'),
          app_name: process.env.VUE_APP_DB_APP_NAME,
          sort_type: 'name',
          from_node: 0,
          language_id: languageID
        })
        .then(resp => {
          if (resp.data.isSuccess) {
            this.items = []
            this.items = this.getNestedChildren(resp.data.data, 0)
          } else {
            this.$swal({type: 'warning', title: resp.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'warning', title: e.message})
        })
      },
      setBorderWidth () {
        /* Golf COmments 2022-11-8 */
        /* let i = this.$refs.drawer.$el.querySelector(
          '.v-navigation-drawer__border'
        )
        i.style.width = this.navigation.borderSize + 'px'
        i.style.cursor = 'ew-resize' */
      },

      setEvents () {
        /* Golf COmments 2022-11-8 */
        /* const minSize = this.navigation.borderSize
        const el = this.$refs.drawer.$el
        const drawerBorder = el.querySelector('.v-navigation-drawer__border')
        const vm = this
        const direction = el.classList.contains('v-navigation-drawer--right')
          ? 'right'
          : 'left'

        function resize (e) {
          document.body.style.cursor = 'ew-resize'
          let f = direction === 'right'
            ? document.body.scrollWidth - e.clientX
            : e.clientX
          el.style.width = f + 'px'
        }

        drawerBorder.addEventListener(
          'mousedown',
          function (e) {
            if (e.offsetX < minSize) {
              el.style.transition = 'initial'; document.addEventListener('mousemove', resize, false)
            }
          },
          false
        )

        document.addEventListener(
          'mouseup',
          function () {
            el.style.transition = ''
            vm.drawerWidth = el.style.width
            document.body.style.cursor = ''
            document.removeEventListener('mousemove', resize, false)
          },
          false
        ) */
      }
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
<style>
/* width */
.v-navigation-drawer__content::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.v-navigation-drawer__content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background: powderblue; 
  border-radius: 10px;
}

/* Handle on hover */
.v-navigation-drawer__content::-webkit-scrollbar-thumb:hover {
  background: #5B65B2; 
}
</style>